<template>
  <div class="flix-form-group">
    <addBtn :onClick="function () { addService() }"><template v-slot:text>{{ $t('message.add', {name: $tc('message.service', 1)}) }}</template></addBtn>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    services: Array
  },
  data () {
    return {
      names: this.$t('demo.service.data'),
      count: 0
    }
  },
  methods: {
    addService () {
      this.services.splice(0, 0, {
        title: this.names[this.count].title,
        description: this.$t('demo.service.description', { name: this.names[this.count].name }),
        price: this.names[this.count].price,
        time: this.names[this.count].time,
        short: [this.names[this.count].name, this.$t('demo.service.category')],
        ID: new Date().getTime()
      })
      this.$store.dispatch('setAddService', this.services)
      this.count++
      if (this.count === this.names.length) {
        this.count = 0
      }
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
